<template>
  <CHeader position="sticky">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <!-- <CIcon icon="cil-menu" size="lg" /> -->
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 2H16" stroke="#3D58B0" stroke-width="2" />
          <path d="M0 8H16" stroke="#3D58B0" stroke-width="2" />
          <path d="M0 14H16" stroke="#3D58B0" stroke-width="2" />
        </svg>
      </CHeaderToggler>
      <CHeaderBrand class="d-lg-none" to="/"> </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-3">
        <CNavItem>
          <CNavLink href="/dashboard"> {{ eventname }} </CNavLink>
        </CNavItem>
      </CHeaderNav>
      <CBadge color="danger" class="me-auto">{{ role }}</CBadge>
      <!-- <CHeaderNav class="ms-auto me-4">
        <CButtonGroup aria-label="Theme switch">
          <CFormCheck
            id="btn-light-theme"
            type="radio"
            :button="{ color: 'primary' }"
            name="theme-switch"
            autocomplete="off"
            :checked="$store.state.theme === 'default'"
            @change="
              (event) =>
                $store.commit({
                  type: 'toggleTheme',
                  value: 'default',
                })
            "
          >
            <template #label><CIcon icon="cil-sun" /></template>
          </CFormCheck>
          <CFormCheck
            id="btn-dark-theme"
            type="radio"
            :button="{ color: 'primary' }"
            name="theme-switch"
            autocomplete="off"
            :checked="$store.state.theme === 'dark'"
            @change="
              (event) =>
                $store.commit({
                  type: 'toggleTheme',
                  value: 'dark',
                })
            "
          >
            <template #label><CIcon icon="cil-moon" /></template>
          </CFormCheck>
        </CButtonGroup>
      </CHeaderNav> -->
      <CHeaderNav>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
export default {
  name: 'AppHeader',
  components: {
    AppHeaderDropdownAccnt,
  },
  data() {
    return {
      eventname: this.$cookies.get('eventname'),
      role: this.$cookies.get('role'),
    }
  },
}
</script>
