<template>
  <div>
    <CToaster class="p-1" placement="top-end">
      <CToast
        :delay="2000"
        v-for="(toast, index) in toasts"
        color="success"
        class="text-white"
        visible
        :key="index"
      >
        <div class="d-flex">
          <CToastBody>
            {{ toast.title }}
          </CToastBody>
          <CToastClose class="me-2 m-auto" white />
        </div>
      </CToast>
    </CToaster>
    <AppSidebar v-if="apikey != undefined" />
    <div
      class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent"
    >
      <AppHeader v-if="apikey != undefined" />
      <CContainer v-if="apikey != undefined" fluid>
        <CBreadcrumb>
          <CBreadcrumbItem v-if="$route.name !== 'Главная'" href="#/main"
            >Главная</CBreadcrumbItem
          >
          <CBreadcrumbItem active>{{ $route.name }}</CBreadcrumbItem>
        </CBreadcrumb>
      </CContainer>
      <div class="body flex-grow-1">
        <CContainer fluid>
          <router-view />
        </CContainer>
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import CToaster from '@coreui/vue/src/components/toast/CToaster'
import { mapState } from 'vuex'

export default {
  name: 'DefaultLayout',
  data() {
    return {
      apikey: this.$cookies.get('apikey'),
    }
  },
  computed: {
    ...mapState(['toasts']),
  },
  mounted() {
    console.log(this.$cookies.get('apikey'))
  },
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
    CToaster,
  },
}
</script>
