<template>
  <CFooter>
    <div class="ms-auto">
      <span class="me-1"
        >Powered by
        <a href="https://ruvents.com" target="_blank">RUVENTS</a>
      </span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
