import {
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilLockLocked,
  cilMenu,
  cilOptions,
  cilSettings,
  cilSpeedometer,
  cilStar,
  cilUser,
  cilAccountLogout,
  cilSun,
  cilMoon,
} from '@coreui/icons'

export const iconsSet = Object.assign(
  {},
  {
    cilArrowBottom,
    cilArrowRight,
    cilArrowTop,
    cilLockLocked,
    cilMenu,
    cilOptions,
    cilSettings,
    cilSpeedometer,
    cilStar,
    cilUser,
    cilAccountLogout,
    cilSun,
    cilMoon,
  },
)
