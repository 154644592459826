import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue-pro'

import CIcon from '@coreui/icons-vue'
import VueCookies from 'vue-cookies'
import { iconsSet as icons } from '@/assets/icons'
import configs from './configs'
import configs_accred from './configs_accred'
import configs_ruvents_dev from './configs_ruvents_dev'
import configs_crki_art from './configs_crki_art'
import configs_accred_ruvents_dev from './configs_accred_ruvents_dev'

const vueConfig = require('vue-config')
const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueCookies, { expires: '2d' })
// set default config
//app.$cookies.config('2d')
//app.use(RuventsCoreuiVue)
/*app.use(VueCookies, {
  expireTimes: '2d',
  path: '/',
  domain: '',
  secure: false,
  sameSite: 'None',
})*/

if (window.location.hostname == 'contractors.accred.ru') {
  app.use(vueConfig, configs_accred)
  document.title = configs_accred.Title
} else if (window.location.hostname == 'bms.ruvents.dev') {
  app.use(vueConfig, configs_ruvents_dev)
  document.title = configs_ruvents_dev.Title
} else if (window.location.hostname == 'contractors.crki.art') {
  app.use(vueConfig, configs_crki_art)
  document.title = configs_crki_art.Title
} else if (window.location.hostname == 'contractors.accred.ruvents.dev') {
  app.use(vueConfig, configs_accred_ruvents_dev)
  document.title = configs_accred_ruvents_dev.Title
} else {
  app.use(vueConfig, configs)
  document.title = configs.Title
}

app.provide('icons', icons)
app.component('CIcon', CIcon)

app.mount('#app')
