<template>
  <div v-if="apikey == undefined"></div>
  <div class="btn-wrap" v-else>
    <CButton color="outline-dark">{{ fio }}</CButton>
    <CButton
      color="success"
      v-c-tooltip="{ content: 'Изменить пароль', placement: 'bottom' }"
      v-on:click="changepassword()"
    >
      <!-- <CIcon name="cil-lock-locked"/> -->
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2438_708)">
          <path
            d="M11 5.33397H5.33373V4.81866C5.33401 3.26204 6.59611 2.00041 8.15273 2.00069C9.17764 2.00087 10.1217 2.5574 10.618 3.45406C10.8861 3.93691 11.4948 4.11105 11.9776 3.84304C12.4605 3.57502 12.6346 2.96631 12.3666 2.48347C11.0767 0.155931 8.14408 -0.68517 5.81654 0.604778C4.28464 1.45379 3.33404 3.06721 3.33386 4.81866V5.7386C2.10781 6.36428 1.33548 7.62392 1.33398 9.00039V12.3335C1.3362 14.3575 2.97641 15.9978 5.0004 15.9999H11C13.024 15.9977 14.6643 14.3575 14.6664 12.3335V9.00039C14.6643 6.9764 13.024 5.33619 11 5.33397ZM12.6666 12.3335C12.6666 13.2539 11.9205 14.0001 11 14.0001H5.00044C4.08003 14.0001 3.33389 13.2539 3.33389 12.3335V9.00039C3.33389 8.07998 4.08003 7.33384 5.00044 7.33384H11C11.9205 7.33384 12.6666 8.07998 12.6666 9.00039V12.3335Z"
            fill="#3D58B0"
          />
          <path
            d="M7.66693 9.33321H8.33354C8.88578 9.33321 9.33347 9.7809 9.33347 10.3331C9.33347 10.8854 8.88578 11.3331 8.33354 11.3331H7.66693C7.11468 11.3331 6.66699 10.8854 6.66699 10.3331C6.66699 9.7809 7.11468 9.33321 7.66693 9.33321Z"
            fill="#3D58B0"
          />
        </g>
        <defs>
          <clipPath id="clip0_2438_708">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </CButton>
    <CButton
      color="danger"
      v-c-tooltip="{ content: 'Выход', placement: 'bottom' }"
      v-on:click="logout()"
    >
      <!-- <CIcon name="cil-account-logout"/> -->
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2438_712)">
          <path
            d="M7.66667 10.6667C7.40145 10.6667 7.1471 10.772 6.95956 10.9596C6.77202 11.1471 6.66667 11.4015 6.66667 11.6667V12.2C6.66667 12.6774 6.47702 13.1352 6.13946 13.4728C5.80189 13.8104 5.34406 14 4.86667 14H3.8C3.32261 14 2.86477 13.8104 2.52721 13.4728C2.18964 13.1352 2 12.6774 2 12.2V3.8C2 3.32261 2.18964 2.86477 2.52721 2.52721C2.86477 2.18964 3.32261 2 3.8 2H4.86667C5.34406 2 5.80189 2.18964 6.13946 2.52721C6.47702 2.86477 6.66667 3.32261 6.66667 3.8V4.33333C6.66667 4.59855 6.77202 4.8529 6.95956 5.04044C7.1471 5.22798 7.40145 5.33333 7.66667 5.33333C7.93188 5.33333 8.18624 5.22798 8.37377 5.04044C8.56131 4.8529 8.66667 4.59855 8.66667 4.33333V3.8C8.66561 2.7925 8.26491 1.82657 7.5525 1.11416C6.84009 0.401755 5.87416 0.00105885 4.86667 0L3.8 0C2.7925 0.00105885 1.82657 0.401755 1.11416 1.11416C0.401755 1.82657 0.00105885 2.7925 0 3.8L0 12.2C0.00105885 13.2075 0.401755 14.1734 1.11416 14.8858C1.82657 15.5982 2.7925 15.9989 3.8 16H4.86667C5.87416 15.9989 6.84009 15.5982 7.5525 14.8858C8.26491 14.1734 8.66561 13.2075 8.66667 12.2V11.6667C8.66667 11.4015 8.56131 11.1471 8.37377 10.9596C8.18624 10.772 7.93188 10.6667 7.66667 10.6667Z"
            fill="#CE3A3A"
          />
          <path
            d="M15.0405 6.34952L11.9832 3.29219C11.8903 3.19934 11.78 3.1257 11.6587 3.07547C11.5373 3.02524 11.4073 2.9994 11.2759 2.99944C11.1446 2.99947 11.0146 3.02536 10.8932 3.07565C10.7719 3.12594 10.6617 3.19963 10.5688 3.29252C10.3813 3.48012 10.276 3.73452 10.2761 3.99976C10.2761 4.13109 10.302 4.26113 10.3523 4.38245C10.4026 4.50378 10.4763 4.61401 10.5692 4.70685L12.8432 6.98085L4.6665 6.99952C4.40129 6.99952 4.14693 7.10488 3.9594 7.29241C3.77186 7.47995 3.6665 7.7343 3.6665 7.99952C3.6665 8.26474 3.77186 8.51909 3.9594 8.70663C4.14693 8.89416 4.40129 8.99952 4.6665 8.99952L12.8785 8.98085L10.5672 11.2922C10.3796 11.4797 10.2741 11.734 10.2741 11.9993C10.274 12.2645 10.3793 12.5189 10.5668 12.7065C10.7543 12.8941 11.0087 12.9995 11.2739 12.9996C11.5392 12.9997 11.7936 12.8944 11.9812 12.7069L15.0385 9.64952C15.4756 9.21176 15.7213 8.61854 15.7216 7.99993C15.722 7.38132 15.4771 6.78781 15.0405 6.34952Z"
            fill="#CE3A3A"
          />
        </g>
        <defs>
          <clipPath id="clip0_2438_712">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </CButton>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'TheHeaderDropdownAccnt',
  data() {
    return {
      fio: this.$cookies.get('fio'),
      apikey: this.$cookies.get('apikey'),
      secret: this.$cookies.get('secret'),
      role: this.$cookies.get('role'),
    }
  },
  mounted() {
    axios.defaults.headers.post['Content-Type'] =
      'application/x-www-form-urlencoded'
    axios.defaults.headers.common['ApiKey'] = this.$cookies.get('apikey')
    axios.defaults.headers.common['Secret'] = this.$cookies.get('secret')
    if (this.$cookies.get('apikey') != undefined) {
      axios
        .get(this.$config.API + 'sas/user/extendsession')
        .then((response) => {
          if (response.data.Error == undefined) {
            if (response.data.response == false) {
              this.$cookies.remove('apikey')
              this.$cookies.remove('secret')
              this.$cookies.remove('fio')
              this.$cookies.remove('eventname')
              this.$cookies.remove('eventlogo')
              this.$cookies.remove('role')
              this.fio = this.$cookies.get('fio')
              this.apikey = this.$cookies.get('apikey')
              this.secret = this.$cookies.get('secret')
              this.role = this.$cookies.get('role')
              axios.defaults.headers.common['ApiKey'] =
                this.$cookies.get('apikey')
              axios.defaults.headers.common['Secret'] =
                this.$cookies.get('secret')
              location.reload()
              location.href = '/'
            } else if (response.data.response == true) {
              this.$cookies.set('apikey', this.$cookies.get('apikey'))
              this.$cookies.set('secret', this.$cookies.get('secret'))
              this.$cookies.set('fio', this.$cookies.get('fio'))
              this.$cookies.set('eventname', this.$cookies.get('eventname'))
              this.$cookies.set('eventlogo', this.$cookies.get('eventlogo'))
              this.$cookies.set('role', this.$cookies.get('role'))
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  methods: {
    changepassword: function () {
      this.$router.push({ path: `/changepassword` })
    },
    logout: function () {
      axios
        .get(this.$config.API + 'sas/user/logout')
        .then((response) => {
          if (
            response.data.Error == undefined ||
            response.data.response == false
          ) {
            this.$cookies.remove('apikey')
            this.$cookies.remove('secret')
            this.$cookies.remove('fio')
            this.$cookies.remove('eventname')
            this.$cookies.remove('eventlogo')
            this.$cookies.remove('eventid')
            this.$cookies.remove('role')
            this.fio = this.$cookies.get('fio')
            this.apikey = this.$cookies.get('apikey')
            this.secret = this.$cookies.get('secret')
            axios.defaults.headers.common['ApiKey'] =
              this.$cookies.get('apikey')
            axios.defaults.headers.common['Secret'] =
              this.$cookies.get('secret')
            this.role = this.$cookies.get('role')
            location.href = '/'
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
